import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Honeypot from "./pages/Honeypot";

import Typography from "@material-ui/core/Typography";

const font =  "'Fjalla One', sans-serif";
const styles = (theme) => ({
  contact: {
    marginBottom: "100px",
  },
  title: {
    marginBottom: theme.spacing(10),
    color: "black",
    // backgroundColor: "#93E9BE",
    paddingRight: "15px",
    paddingLeft: "15px",
    textAlign: "center",
    marginTop: "50px",
    paddingTop: "40px",
    fontFamily: font,
  },
  cardWrapper: {
    zIndex: 1,
  },
  card: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: "#93E9BE",
    padding: theme.spacing(8, 3),
  },
  cardContent: {
    maxWidth: 400,
  },
  textField: {
    width: '100%',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  button: {
    width: '100%',
    color: "white",
    backgroundColor: "black"
  },
  imagesWrapper: {
    position: 'relative',
  },
  imageDots: {
    position: 'absolute',
    top: -67,
    left: -67,
    right: 0,
    bottom: 0,
    width: '100%',
    background: 'url(/static/onepirate/productCTAImageDots.png)',
  },
  // image: {
  //   position: 'absolute',
  //   top: -28,
  //   left: -28,
  //   right: 0,
  //   bottom: 0,
  //   width: '100%',
  //   maxWidth: 600,
  //   height: "550px"
  // },
});

function ProductCTA(props) {
  const { classes } = props;

  return (<>

    <Container id="contact" className={classes.contact} component="section">
      <Typography variant="h4" marked="center" className={classes.title} component="h2">
    Contact
    </Typography>
      <Grid container>
        <Grid item xs={12} md={12} className={classes.cardWrapper}>
          <div className={classes.card}>
            <Honeypot />
          </div>
        </Grid>
        {/* <Grid item xs={12} md={6} className={classes.imagesWrapper}>
          
            <div className={classes.imageDots} />
            <img
              src={image}
              alt="call to action"
              className={classes.image}
            />
         
        </Grid> */}
      </Grid>
      {/* <Snackbar
        open={open}
        onClose={handleClose}
        message="We will send you our best offers, once a week."
      /> */}
    </Container>

    </>
  );
}

ProductCTA.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProductCTA);
