import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import img from "../images/website/IMG_7899.jpg"
// import img1 from "../images/website/IMG_4983.jpg"
// import img2 from "../images/website/IMG_4981.jpg"
import img3 from "../images/website/IMG_4927.jpg"
import img4 from "../images/website/IMG_4921.jpg"
import img5 from "../images/website/IMG_4917.jpg"
import img6 from "../images/website/IMG_4913.jpg"
import img7 from "../images/website/IMG_4912.jpg"
import img8 from "../images/website/IMG_4910.jpg"
import img9 from "../images/website/IMG_4909.jpg"
import img10 from "../images/website/IMG_4643.jpg"
import img11 from "../images/website/IMG_0004.jpg"
import img12 from "../images/website/IMG_0031.jpg"
import img13 from "../images/website/IMG_0036.jpg"
import img14 from "../images/website/IMG_0040.jpg"
import img15 from "../images/website/IMG_0048.jpg"
import img16 from "../images/website/IMG_8523.jpg"
import img17 from "../images/website/IMG_8525.jpg"
import "./overrides.css";

const images = [
  img,
  // img1,
  // img2,
  img3,
  img4,
  img5,
  img6,
  img7,
  img8,
  img9,
  img10,
  img11,
  img12,
  img16,
  img17,
  img13,
  // 
  img15,

]

export default function CarouselShow() {

    return (
             
            <div id="home" className="gallery">
              <Carousel className="carousel-wrapper" showStatus={true} showArrows={true} infiniteLoop useKeyboardArrows autoPlay showThumbs={false}>
              {
               images.map((item) =>(
                    <img key={item} alt="" src={item} />
               ))
              }
            </Carousel>
            </div>
    
    );
}



