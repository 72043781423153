import {
  AppBar,
  Toolbar,
  Typography,
  makeStyles,
  Button,
  IconButton,
  Drawer,
  MenuItem,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import React, { useState, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import {Link as Scroll }  from "react-scroll";

const useStyles = makeStyles(() => ({
  header: {
    backgroundColor: "#93E9BE",
    paddingRight: "1px",
    paddingLeft: "1px",
    "@media (max-width: 900px)": {
      paddingLeft: 0,
    },
  },
  logo: {
    fontFamily: "Work Sans, sans-serif",
    fontWeight: 600,
    color: "#000",
    textAlign: "left",
  },
  menuButton: {
    fontFamily: "Open Sans, sans-serif",
    fontWeight: 700,
    size: "18px",
    marginLeft: "38px",
    color: "#000"
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
  },
  drawerContainer: {
    padding: "20px 30px",
  },
}));

export default function Header() {
  const { header, logo, menuButton, toolbar, drawerContainer } = useStyles();

  const [state, setState] = useState({
    mobileView: false,
    drawerOpen: false,
  });

  const { mobileView, drawerOpen } = state;

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 900
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };

    setResponsiveness();

    window.addEventListener("resize", () => setResponsiveness());
  }, []);

  const displayDesktop = () => {
    return (
      <Toolbar className={toolbar}>
        {femmecubatorLogo}
        <div>{getMenuButtons()}</div>
      </Toolbar>
    );
  };

  const displayMobile = () => {
    const handleDrawerOpen = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: true }));
    const handleDrawerClose = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: false }));

    return (
      <Toolbar>
        <IconButton
          {...{
            edge: "start",
            color: "default",
            "aria-label": "menu",
            "aria-haspopup": "true",
            onClick: handleDrawerOpen,
          }}
        >
          <MenuIcon />
        </IconButton>

        <Drawer
          {...{
            anchor: "left",
            open: drawerOpen,
            onClose: handleDrawerClose,
          }}
        >
          <div className={drawerContainer}>{getDrawerChoices()}</div>
        </Drawer>

        <div>{femmecubatorLogo}</div>
      </Toolbar>
    );
  };

  const getDrawerChoices = () => {
      return (
        <>
        <Scroll to="home" smooth={true} offset={-95}>
        <MenuItem>Home</MenuItem>
        
        </Scroll>
      
    
      <Scroll to="about" smooth={true} offset={-100}>
        <MenuItem>About</MenuItem>
      </Scroll>
      
       <Scroll to="contact" smooth={true} offset={-100}>
          <MenuItem>Contact</MenuItem>
       </Scroll>
        </>
      );
  };

  const femmecubatorLogo = (
   
    <Scroll to="home" smooth={true} offset={-95}>
      <MenuItem>
          <Typography variant="h6" component="h1" className={logo}>
      COASTAL ISLAND COVE
      </Typography>
      </MenuItem>
      </Scroll>
  );

  const getMenuButtons = () => {
      return (
        <>
        <Button
          {...{
            color: "inherit",
         
            component: RouterLink,
            className: menuButton,
          }}
        >
        <Scroll to="home" smooth={true} offset={-95}>
                  home
              </Scroll>
        </Button>
          <Button
                {...{
                  color: "inherit",
                  component: RouterLink,
                  className: menuButton,
                }}
              >
                <Scroll to="about" smooth={true} offset={-95}>
                  About
              </Scroll>
              </Button>
                
                <Button
                {...{
                  color: "inherit",
                  component: RouterLink,
                  className: menuButton,
                }}
              >
              <Scroll to="contact" smooth={true} offset={-100}>
                  Contact
              </Scroll>
              </Button>
              </>
      );
  };

  return (
    <header>
      <AppBar className={header}>
        {mobileView ? displayMobile() : displayDesktop()}
      </AppBar>
    </header>
  );
}