import firebase from 'firebase';

const firebaseConfig = {
  apiKey: "AIzaSyCm75SyoLtza7HF_66PrDrZaYGVZu25qvQ",
  authDomain: "coastalislandcove-e733d.firebaseapp.com",
  projectId: "coastalislandcove-e733d",
  storageBucket: "coastalislandcove-e733d.appspot.com",
  messagingSenderId: "777166146522",
  appId: "1:777166146522:web:f471aef0b3d0549f308ee3"
};

//initialize a Firebase instance
firebase.initializeApp(firebaseConfig);

firebase.functions();

export default firebase;