import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./Footer.css"
import {
  faTwitter,
  faInstagram
} from "@fortawesome/free-brands-svg-icons";
const font =  "'Fjalla One', sans-serif";

function Copyright() {
  const classes = useStyles();
  return (
    <Typography className={classes.title} variant="body2" color="textSecondary" align="center">
      {"Copyright © "} {" " + new Date().getFullYear()}
      <hr></hr>
      <Link className={classes.title} color="inherit">
        coastalislandcove.com
      </Link>
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  footer: {
    // padding: theme.spacing(5),
    
    // [theme.breakpoints.down('md')]: {
    //   marginTop: "1200px"
    // },
    // [theme.breakpoints.down('sm')]: {
    //   marginTop: "1000px"
    // },
    // [theme.breakpoints.up('lg')]: {
    //   marginTop: "400px"
    // },
  },
  title: {
    fontFamily: font,
  },

}));

export default function Footer() {
  const classes = useStyles();

  return (
    <footer className={classes.footer}>
      <Container maxWidth="lg">
        <Typography variant="h6" align="center" gutterBottom className={classes.title}>
          Coastal Island Cove
        </Typography>
        <Copyright />
         <div className="social-container">
       <div> 
    
        <button className="twitter social">
        <FontAwesomeIcon icon={faTwitter} size="2x" />
        </button>


      </div>
    <div>

    <a href="https://www.instagram.com/coastalislandcove_fl/?r=nametag"
      className="instagram social">
      <FontAwesomeIcon icon={faInstagram} size="2x" />
    </a>
    </div>

</div>
      </Container>
    </footer>
  );
}
