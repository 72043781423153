import React from "react";
import "fontsource-roboto";
import CssBaseline from "@material-ui/core/CssBaseline";
import { Route, Switch } from "react-router-dom";
import NavBar from "./components/Navbar";
import Footer from "./Footer";
import Home from "./pages/Home";


function App() {
  return (
  <div className="App">
    <CssBaseline />
    <NavBar />
    <Switch>
      <Route exact path="/" component={Home} />
      <Route component={Home} />
    </Switch>
    <Footer />
  </div>
  );
}


export default App;
