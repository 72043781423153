import React from 'react';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import FadeInSection from "./components/FadeInSection";

const font =  "'Fjalla One', sans-serif";



const useStyles = makeStyles((theme) => ({
  about_: {
    marginBottom: "50px"
  },
  title: {
    color: "black",
    // backgroundColor: "#93E9BE",
    paddingRight: "15px",
    paddingLeft: "15px",
    textAlign: "center",
    marginTop: "50px",
    marginBottom: "-20px",
    fontFamily: font,
  },
  title1: {
    color: "black",
    // backgroundColor: "#93E9BE",
    paddingRight: "15px",
    paddingLeft: "15px",
    textAlign: "center",
    marginTop: "20px",
    marginBottom: "20px",
    fontFamily: font,
  },
  button: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(8),
    backgroundColor: "black",
    color: "white",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginRight: "50px",
  },
  footer: {
    borderTop: `1px solid ${theme.palette.divider}`,
    marginTop: theme.spacing(8),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
    },
  },
  aboutList: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    fontSize: "20px",
    fontFamily: font,
    [theme.breakpoints.down('sm')]: {
      flexDirection: "column",
    },
  },
  list:  {
    width: "50%",
    [theme.breakpoints.down('sm')]: {
      width: "70%",
    },
  
  },
  list1: {
    paddingRight: "20px"
  },
  list2: {
    // paddingLeft: "20px",
    paddingBottom: "40px",
    [theme.breakpoints.down('sm')]: {  
      paddingBottom: "90px"
    },
  },
}));


export default function About() {

  const classes = useStyles();

  return (
    <>
    <div className={classes.about_} id="about">
      <FadeInSection>
      <Typography  className={classes.title} align="center" variant="h3" color="textPrimary" gutterBottom>
             Coastal Island Cove
        </Typography>

      </FadeInSection>
      <FadeInSection>
          <Typography className={classes.title} align="center" variant="h5" color="textPrimary" gutterBottom>
             Your Own Piece of Paradise located in Florida's Sunset Capital
             </Typography>
        
  </FadeInSection>
  <FadeInSection>
  <Container id="" maxWidth="lg" component="footer" className={classes.footer}>
        <Typography className={classes.title1} variant="h5" color="textPrimary" gutterBottom>
             PRIVATE CONDO RENTAL DETAILS
             </Typography>
      
        <div className={classes.aboutList}>
          <ul className={classes.list}>
              <li>Located in St. Petersburg, Florida on a private golf course</li>
              <li>Contactless Check-in and Check-Out</li>
              <li>2 Bedroom/2 Bath Condo - Maximum 6 guests</li>
              <li>Weekly Rental</li>
              <li>Community Swimming Pool and Hot Tub Access</li>
              <li>Private Beach Access</li>
              <li>No Parties Allowed</li>
              <li>No Smoking</li>
              
          </ul>
          <ul className={classes.list} >
          <li>No Pets Allowed</li>
         
              <li>24/7 Security Patrol</li>
              <li>Minutes Away From St. Pete Beach (ranked #1 beach in America by Tripadvisor) and Passe-A-Grille Beach</li>
              <li>Minimum age of primary renter is 25. Primary renter must stay on premise during entire rental period</li>
              <li>Ability to e-sign rental agreement</li>
              <li>Refundable damage deposit $300</li>
          </ul>
        
          </div>
        </Container>
          </FadeInSection>
          <FadeInSection>
<Typography className={classes.title} align0="center" variant="h5" color="textPrimary" gutterBottom>
             Top attractions include: St. Pete Beach (1 mile), Ft. De Soto Park (6.5 miles), Tampa International Airport (26 miles),
             Seminole Hardrock Casino (36 miles), Disney World (94 miles)
             </Typography>
             </FadeInSection>
        </div>
      </>
  );
}
