import React from 'react'
import StayInContact from "../StayInContact";
import About from "../About";
import Container from "@material-ui/core/Container";
import CarouselShow from "./Carousel";
import FadeInSection from "../components/FadeInSection";
// import VirtualTour from "../VirtualTour"

export default function Home () {
    return(
    <>
    
    <Container maxWidth="lg">
        <main>
        <CarouselShow />
        <About />    
         {/* <FadeInSection key={"virtualtour"} >
         <VirtualTour  />
        </FadeInSection> */}
        <FadeInSection key={"stay"} >
        <StayInContact />
        </FadeInSection>
        </main>
      </Container>
    </>
    )   
}